/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 12/12/2023
 * All Rights Reserved.
 */
import { Box, Button, Divider, IconButton, Paper, Typography } from "@mui/material";
import { style } from './style';
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { CrossIcon, IntFieldController, IntUploadFile } from "../../../global";
import { ChangeEvent } from "react";
import { AudienceForm } from "../../types/audience";
import { AudienceService } from "../../services";
import { StatusCode } from "../../../global/types";
import { NotificationService } from "../../../global/services";
import { AUDIENCE_FORM_DEFAULT_VALUES, getCreateAudienceValidationSchema } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import { selectCurrentUserSpaceId } from "../../../store/reducers/authentication";

type Props = {
  onClose: () => void;
  getAudienceList: () => void;
}

function CreateAudience({ onClose, getAudienceList }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'audience' });

  const userId = useSelector(selectCurrentUserSpaceId)

  const {
    control,
    setValue,
    handleSubmit,
    formState: {
      isSubmitting,
      errors,
    },
  } = useForm<AudienceForm>({
    defaultValues: AUDIENCE_FORM_DEFAULT_VALUES,
    resolver: yupResolver(getCreateAudienceValidationSchema()),
  });

  const file = useWatch({
    name: 'file',
    control,
  });


  function handleCSVUpload(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      setValue('file', file);
    }
  }

  async function onSubmit(formValues: AudienceForm) {
    const { data } = await AudienceService.uploadAudience(formValues, userId);
    // eslint-disable-next-line
    if (data.type?.code == StatusCode.OK) {
      NotificationService.handleSuccess(t('uploadSuccess'));
      onClose();
      getAudienceList();
    } else if (data.type?.code === 186) {
      NotificationService.handleError(t('emptyFile'));
    } else {
      NotificationService.handleError(t('somethingWentWrong'));
    }
  }

  return (
    <Paper sx={style.paper} component='form' onSubmit={handleSubmit(onSubmit)}>
      <Box sx={style.header}>
        <Typography sx={style.title}>{t('create')}</Typography>
        <IconButton size='small' sx={style.iconButton} onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </Box>
      <Divider />
      <Box sx={style.form}>
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('name')}</Typography>
          <IntFieldController control={control} name='name' />
        </Box>
        <IntUploadFile
          classes={{ root: style.uploadFile }}
          onFileChange={handleCSVUpload}
          accept='.csv'
          uploaded={!!file}
          hasError={!!errors.file}
        />
      </Box>
      <Divider />
      <Box sx={style.footer}>
        <Button variant='text' sx={style.cancel} onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          variant='contained'
          type='submit'
          disabled={isSubmitting}
          sx={style.button}
        >{t('upload')}</Button>
      </Box>
    </Paper>
  )
}

CreateAudience.displayName = 'CreateAudience';

export default CreateAudience;