import { createModel } from "@rematch/core";
import {
  FetchUtils,
  WebStorageUtils,
  setDictionaryField,
  getDictionaryField,
  DictionaryUtils,
} from "@intent-ai/mandal-npm-lib-v2/dist/utils";
import {
  IDictionaryResponse,
  IDictionaryResponseList,
  IDictionaryField,
  IDictionaryFiledGet,
} from "../types";
import { DICT_HASH_KEY } from "../_constants";
import { getDictionaryRequest, getDictionaryHashRequest } from "../api";

const { formatList, formatSSPTree, formatDspCreatives } = DictionaryUtils;
const { get: LSGet, update: LSSet } = WebStorageUtils;

const { makeRequest } = FetchUtils;

const formatTree = ({ meta: data }: any, depth = 2) => {
  data = data.sort((a: number, b: number) => a - b);
  const firstLevel: any = {};
  const secondLevel: any = {};
  const thirdLevel: any = {};
  data.forEach((item: any) => {
    const level = item.value.replace(/[^_]/g, "").length;
    if (level === 0) {
      firstLevel[item.value] = {
        key: item.key,
        value: item.value,
        title: item.title,
      };
    } else if (level === 1) {
      secondLevel[item.value] = {
        key: item.key,
        value: item.value,
        title: item.title,

      };
    } else if (level === 2) {
      thirdLevel[item.value] = {
        key: item.key,
        value: item.value,
        title: item.title,

      };
    }
  });
  if (depth === 3) {
    Object.keys(thirdLevel).forEach((key) => {
      const value = key.split("_");
      if (!secondLevel[`${value[0]}_${value[1]}`].children) {
        secondLevel[`${value[0]}_${value[1]}`].children = [];
      }
      secondLevel[`${value[0]}_${value[1]}`].children.push(thirdLevel[key]);
    });
  }

  Object.keys(secondLevel).forEach((key) => {
    const value = key.split("_");
    if (!firstLevel[value[0]].children) {
      firstLevel[value[0]].children = [];
    }
    firstLevel[value[0]].children.push(secondLevel[key]);
  });
  const result: any = [];
  Object.keys(firstLevel).forEach((key) => {
    result.push(firstLevel[key]);
  });
  return result;
};


export const saveDictionary = (dictionary: IDictionaryResponseList[]) =>
  dictionary.forEach((item: IDictionaryResponseList) => {
    if (item.id.includes("visuals_banners")) {
      const { vertical, horizontal, square } = formatDspCreatives(item);
      setDictionaryField(`visuals_banners_horizontal`, "en_US", horizontal);
      setDictionaryField(`visuals_banners_vertical`, "en_US", vertical);
      setDictionaryField(`visuals_banners_square`, "en_US", square);
    }
    if (item.id.includes("visuals_fullscreen")) {
      const { vertical, horizontal, square } = formatDspCreatives(item);
      setDictionaryField(`visuals_fullscreen_horizontal`, "en_US", horizontal);
      setDictionaryField(`visuals_fullscreen_vertical`, "en_US", vertical);
      setDictionaryField(`visuals_fullscreen_square`, "en_US", square);
    }
    if (
      item.id.includes("iab_categories") ||
      item.id.includes("locations")
      ) {
        setDictionaryField(item.id, "en_US", formatSSPTree(item, 2));
      } else {
        setDictionaryField(item.id, "en_US", formatList(item));
      }
      if (item.id.includes("interests")) {
        setDictionaryField(item.id, "en_US", formatTree(item, 2));
      }
      if (item.id.includes("languages")) {
        setDictionaryField(item.id, "en_US", formatTree(item, 2));
      }
  });




export interface DictionaryState {
  isLoaded: boolean;
  data: { [key: string]: IDictionaryField[] };
  dictFiledLoading: boolean;
}

export const dictionary = createModel({
  name: "dictionary",
  state: {
    isLoaded: false,
    data: {},
    dictFiledLoading: false,
  } as DictionaryState,
  reducers: {
    dictLoaded: (state: DictionaryState): DictionaryState => ({
      ...state,
      isLoaded: true,
    }),
    dictFiledLoadingStart: (state: DictionaryState): DictionaryState => ({
      ...state,
      dictFiledLoading: true,
    }),
    dictFiledLoadingFinish: (state: DictionaryState): DictionaryState => ({
      ...state,
      dictFiledLoading: false,
    }),
    dictFieldGet: (
      state: DictionaryState,
      payload: IDictionaryFiledGet
    ): DictionaryState => ({
      ...state,
      data: { ...state.data, [payload.id]: payload.data },
    }),
  },
  selectors: (state) => ({
    // selectDictionaryFieldsIsLoadeding() {
    //   return (rootState: IStore): boolean => {
    //     return rootState.dictionary.dictFiledLoading;
    //   };
    // },
    selectDictionaryField() {
      return (rootState: any, ...fieldName: any): IDictionaryField[] => {
        const name = fieldName[0];
        if (rootState.dictionary.data[name]) {
          return rootState.dictionary.data[name];
        }
        return [];
      };
    },
  }),
  effects: (dispatch) => ({
    async getDictionary() {
      const currentHash = Number(LSGet(DICT_HASH_KEY));
      if (!currentHash) {
        await dispatch.dictionary.updateDictionary();
      } else {
        await makeRequest(
          getDictionaryHashRequest(),
          {
            success: async (hash: number) => {
              if (currentHash !== hash) {
                await dispatch.dictionary.updateDictionary();
              }
              dispatch.dictionary.dictLoaded();
            },
            fail: (err: any) => {
              console.error(err);
            },
          },
          false
        );
      }
    },
    async updateDictionary() {
      await makeRequest(
        getDictionaryRequest(),
        {
          success: ({ dictionary, hash }: IDictionaryResponse) => {
            LSSet(DICT_HASH_KEY, hash);
            saveDictionary(dictionary);
            dispatch.dictionary.dictLoaded();
          },
          fail: (err: any) => {
            console.error(err);
          },
        },
        false
      );
    },
    async getDictionaryValues(fields: string[], ...rest) {
      dispatch.dictionary.dictFiledLoadingStart();
      const locale = "en_US";
      fields.forEach(async (field: string, index: number) => {
        const data = await getDictionaryField(field, locale);
        dispatch.dictionary.dictFieldGet({ id: field, data });
        if (fields.length - 1 === index) {
          dispatch.dictionary.dictFiledLoadingFinish();
        }
      });
    },
  }),
});
