import { createSlice } from '@reduxjs/toolkit';
import {
  IAdminAccountsTotals, IAdminAccountsUser,
  IAdminPublisher,
  IAdminSearchResult,
  IAdminWithdrawHistory, ICampaign, IPaginationData,
  IUser,
  IWhitelabel,
} from "../../../types";
import { TERMS_AND_SERVICE_URL } from "../../../_constants";

export interface IAdminState {
  isModerationRegistrationApproved: boolean;
  adminSearchResult: IAdminSearchResult;
  adminSearchResultLoading: boolean;
  whiteLabelWithdrawHistory: IAdminWithdrawHistory[];
  whitelabel?: IWhitelabel;
  withdrawBalance: number;
  incomeData: any;
  modeerationUsers: null | IPaginationData<IUser[]>;
  legalEntityUsers: null | IPaginationData<IUser[]>;
  publisherList: null | IPaginationData<IAdminPublisher[]>;
  modeerationUser: null | IUser;
  moderationCampaigns: null | IPaginationData<ICampaign>;
  adminAccountsTotals: null | IAdminAccountsTotals;
  adminAccountsTotalsIsLoading: boolean;
  adminAccountsUsers: null | IPaginationData<IAdminAccountsUser[]>;
  adminAccountsUsersIsLoading: boolean;
  adminPublisherIsLoading: boolean;
}

export const adminSlice = createSlice({

  name: "adminStore",
  initialState: {
    adminSearchResult: {
      campaigns: [],
      all: [],
      accounts: [],
      campaigns_in_moderation_count: 0,
      registrants_in_moderation_count: 0,
    },
    isModerationRegistrationApproved: false,
    adminSearchResultLoading: false,
    whitelabel: undefined,
    whiteLabelWithdrawHistory: [],
    withdrawBalance: 0,
    incomeData: null,
    modeerationUsers: null,
    legalEntityUsers: null,
    moderationCampaigns: null,
    publisherList: null,
    modeerationUser: null,
    adminAccountsTotals: null,
    adminAccountsUsers: null,
    adminAccountsUsersIsLoading: false,
    adminAccountsTotalsIsLoading: false,
    adminPublisherIsLoading: false,
  } as IAdminState,
  reducers: {
    setAdminResults: (state, action) => ({
      ...state,
      adminSearchResult: action.payload,
    }),
    setWhiteLabelWithdrawHistory: (state, action) => ({
      ...state,
      whiteLabelWithdrawHistory: action.payload,
    }),
    setAdminResultsLoading: (state, action) => ({
      ...state,
      adminSearchResultLoading: action.payload,
    }),
    setWhitelabel: (state, action) => ({
      ...state,
      whitelabel: action.payload,
    }),
    setWithdrawBalance: (state, action) => ({
      ...state,
      withdrawBalance: action.payload,
    }),
    setIncomeData: (state, action) => ({
      ...state,
      incomeData: action.payload,
    }),
    setModeerationUsers: (state, action) => ({
      ...state,
      modeerationUsers: action.payload,
    }),
    setModeerationUser: (state, action) => ({
      ...state,
      modeerationUser: action.payload,
    }),
    setModeerationCampaigns: (state, action) => ({
      ...state,
      moderationCampaigns: action.payload,
    }),
    setLegalEntityUsers: (state, action) => ({
      ...state,
      legalEntityUsers: action.payload,
    }),
    setModerationRegistrationApproved: (state, action) => ({
      ...state,
      isModerationRegistrationApproved: action.payload,
    }),
    setAdminAccountsTotals: (state, action) => ({
      ...state,
      adminAccountsTotals: action.payload ,
    }),
    setAdminAccountsUsersIsLoading: (state, action) => ({
      ...state,
      adminAccountsUsersIsLoading: action.payload,
    }),
    setAdminPublisherIsLoading: (state, action) => ({
      ...state,
      adminPublisherIsLoading: action.payload,
    }),
    setAdminAccountsTotalsIsLoading: (state, action) => ({
      ...state,
      adminAccountsTotalsIsLoading: action.payload,
    }),
    setAdminAccountsUsers: (state, action) => ({
      ...state,
      adminAccountsUsers: action.payload,
    }),
    setPublisherList: (state, action) => ({
      ...state,
      publisherList: action.payload,
    }),
  },
});

export const {
  setAdminResults,
  setWhiteLabelWithdrawHistory,
  setAdminResultsLoading,
  setWhitelabel,
  setWithdrawBalance,
  setIncomeData,
  setModeerationUsers,
  setModeerationUser,
  setModeerationCampaigns,
  setLegalEntityUsers,
  setModerationRegistrationApproved,
  setAdminAccountsTotals,
  setAdminAccountsUsersIsLoading,
  setAdminPublisherIsLoading,
  setAdminAccountsTotalsIsLoading,
  setAdminAccountsUsers,
  setPublisherList,
} = adminSlice.actions;


export const isModerationRegistrationApprovedSelector = (state: any) => state.adminStore.isModerationRegistrationApproved;
export const adminSearchResultSelector = (state: any) => state.adminStore.adminSearchResult;
export const whiteLabelNameSelector = (state: any) => state.adminStore.whitelabel?.name;
export const whitelabelIdSelector = (state: any) => state.adminStore.whitelabel?.id;
export const whiteLabelWithdrawHistorySelector = (state: any) => state.adminStore.whiteLabelWithdrawHistory;
export const isAdminSearchResultsLoadingSelector = (state: any) => state.adminStore.adminSearchResultLoading;
export const legalEntityUsersSelector = (state: any) => state.adminStore.legalEntityUsers;
export const whitelabelDataSelector = (state: any) => state.adminStore.whitelabel;
export const whitelabelSettingsSelector = (state: any) => state.adminStore.whitelabel?.whitelabel_settings;
export const whitelabelCreationDateSelector = (state: any) => state.adminStore.whitelabel?.created_at;
export const withdrawBalanceSelector = (state: any) => state.adminStore.withdrawBalance;
export const incomeDataSelector = (state: any) => state.adminStore.incomeData;
export const moderationUsersSelector = (state: any) => state.adminStore.modeerationUsers;
export const moderationUserSelector = (state: any) => state.adminStore.modeerationUser;
export const moderationCampaignSelector = (state: any) => state.adminStore.moderationCampaigns;
export const adminAccountsTotalsSelector = (state: any) => state.adminStore.adminAccountsTotals;
export const adminAccountsUsersSelector = (state: any) => state.adminStore.adminAccountsUsers;
export const adminAccountsUsersIsLoadingSelector = (state: any) => state.adminStore.adminAccountsUsersIsLoading;
export const adminPublisherListSelector = (state: any) => state.adminStore.adminAccountsUsersIsLoading;
export const adminPublisherIsLoadingSelector = (state: any) => state.adminStore.adminPublisherIsLoading;
export const adminAccountsTotalsIsLoadingSelector = (state: any) => state.adminStore.adminAccountsTotalsIsLoading;
export const termsOfServiceLinkSelector = (state: any) => state.adminStore.whitelabel?.whitelabel_settings?.term_of_service_url || TERMS_AND_SERVICE_URL;
export const getWhiteLabelCompanyLogo = (state: any) => state.adminStore.whitelabel?.whitelabel_settings?.company_logo;
export const getWhiteLabelTimezoneOffset = (state: any) => state.adminStore.whitelabel?.timezone_offset_seconds && state.adminStore.whitelabel?.timezone_offset_seconds / 60;


export default adminSlice.reducer;
