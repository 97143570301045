/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 8/30/2023
 * All Rights Reserved.
 */
import { style } from "./style";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import { InfoIcon, IntAutocomplete, IntCheckbox, IntFieldController, IntSlider } from "../../../global";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { SelectOption } from "../../../global/components/IntForm/IntSelect";
import DictService from "../../../dict/services/dict.service";
import { Dictionary } from "../../../dict/types/dict";
import SearchSites from "../SearchSites";
import { Gender } from "../../types";
import CustomAudienceList from "../CustomAudienceList";

function CampaignTargetingForm() {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.targeting' });


  const [visibleInputs, setVisibleInputs] = useState({
    exclude: false,
    narrow: false,
    excludeInterests: false,
    excludeAudience: false,
    narrowAudience: false,
  });

  const { control, setValue } = useFormContext();

  const [excludeApps, includeApps, excludeInterests, segmentAudience] = useWatch({
    control,
    name: [
      'targeting.apps.exclude',
      'targeting.apps.include',
      'targeting.interests.exclude',
      'targeting.segmentAudience',
    ],
  });

  useEffect(() => {
    setVisibleInputs(visibleInputs => {
      let copy = { ...visibleInputs };
      if (!visibleInputs.exclude) {
        copy.exclude = !!excludeApps.length;
      }
      if (!visibleInputs.narrow) {
        copy.narrow = !!includeApps.length;
      }
      if (!visibleInputs.excludeInterests) {
        copy.excludeInterests = !!excludeInterests.length;
      }
      if (!visibleInputs.narrowAudience) {
        copy.narrowAudience = !!segmentAudience.include.length;
      }
      if (!visibleInputs.excludeAudience) {
        copy.excludeAudience = !!segmentAudience.exclude.length;
      }
      return copy;
    });
  }, [excludeApps.length, includeApps.length, segmentAudience]);// eslint-disable-line react-hooks/exhaustive-deps

  const [gender, ageRange] = useWatch({
    name: ['targeting.gender', 'targeting.ageRange'],
    control,
  });

  const [interests, setInterests] = useState<Array<SelectOption>>([]);

  function showExcludeInput() {
    setVisibleInputs(inputs => ({
      ...inputs,
      exclude: true,
    }));
  }

  function showExcludeInterestInput() {
    setVisibleInputs(inputs => ({
      ...inputs,
      excludeInterests: true,
    }));
  }

  function showNarrowInput() {
    setVisibleInputs(inputs => ({
      ...inputs,
      narrow: true,
    }));
  }

  function showExcludeAudienceInput() {
    setVisibleInputs(inputs => ({
      ...inputs,
      excludeAudience: true,
    }));
  }

  function showNarrowAudienceInput() {
    setVisibleInputs(inputs => ({
      ...inputs,
      narrowAudience: true,
    }));
  }

  function getInterests() {
    DictService.getDictionary()
      .then((response: any) => {
        const { dictionary }: { dictionary: Dictionary } = response.data;
        const { meta } = (dictionary || []).find(({ id }) => id === 'interests') || { meta: [] };
        setInterests(meta.map(({ value, title }) => ({ value, label: title })));
      });
  }

  useEffect(() => {
    getInterests();
  }, []);

  function handleGenderChange(gender: Gender) {
    setValue('targeting.gender', gender);
  }

  return (
    <Box sx={style.fields}>
      <Typography sx={style.heading}>{t('addManual')}</Typography>
      <Box sx={style.field}>
        <Typography sx={style.label}>{t('includeUsers')}</Typography>
        <SearchSites
          placeholder={t<string>('searchSites')}
          name='targeting.apps.includeAny'
        />
      </Box>
      {visibleInputs.exclude && (
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('excludeUsers')}</Typography>
          <SearchSites
            placeholder={t<string>('searchSites')}
            name='targeting.apps.exclude'
          />
        </Box>
      )}
      {visibleInputs.narrow && (
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('narrowUsers')}</Typography>
          <SearchSites
            placeholder={t<string>('searchSites')}
            name='targeting.apps.include'
          />
        </Box>
      )}
      <Box sx={style.hashtags}>
        {!visibleInputs.exclude && (
          <Box sx={style.hashtagLike} onClick={showExcludeInput}>
            <Typography sx={style.hashtagText}>{t('exclude')}</Typography>
          </Box>
        )}
        {!visibleInputs.narrow && (
          <Box sx={style.hashtagLike} onClick={showNarrowInput}>
            <Typography sx={style.hashtagText}>{t('narrowAudience')}</Typography>
          </Box>
        )}
      </Box>
      <Box sx={style.field}>
        <Typography sx={style.label}>{t('interests')}</Typography>
        <IntFieldController
          control={control}
          name='targeting.interests.includeAny'
          Component={IntAutocomplete}
          options={interests}
          multiple
          placeholder={t<string>('interestPlaceholder')}
          sx={style.autocomplete}
        />
      </Box>
      {visibleInputs.excludeInterests && (
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('excludeInterests')}</Typography>
          <IntFieldController
            control={control}
            name='targeting.interests.exclude'
            Component={IntAutocomplete}
            options={interests}
            multiple
            placeholder={t<string>('interestPlaceholder')}
            sx={style.autocomplete}
          />
        </Box>
      )}
      <Box sx={style.hashtags}>
        {!visibleInputs.excludeInterests && (
          <Box sx={style.hashtagLike} onClick={showExcludeInterestInput}>
            <Typography sx={style.hashtagText}>{t('exclude')}</Typography>
          </Box>
        )}
      </Box>
      <Divider sx={style.divider}/>
      <Box sx={style.demographicFields}>
        <Box>
          <Typography sx={style.label}>{t('gender')}</Typography>
          <Box sx={style.genderSelect}>
            <Box
                /* eslint-disable */
              sx={[style.genderField, gender == Gender.ALL && style.selectedGender]}
              onClick={() => handleGenderChange(Gender.ALL)}
            >
              <Typography sx={style.genderType}>{t('all')}</Typography>
            </Box>
            <Box
              sx={[style.genderField, gender == Gender.MALE && style.selectedGender]}
              onClick={() => handleGenderChange(Gender.MALE)}
            >
              <Typography sx={style.genderType}>{t('men')}</Typography>
            </Box>
            <Box
              sx={[style.genderField, gender == Gender.FEMALE && style.selectedGender]}
              onClick={() => handleGenderChange(Gender.FEMALE)}
                /* eslint-enable */
            >
              <Typography sx={style.genderType}>{t('women')}</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={style.age}>
          <Typography sx={style.label}>{t('age')}</Typography>
          <Box sx={style.ageRangeSlider}>
            <Typography>{ageRange[0]}</Typography>
            <IntFieldController
              control={control}
              name='targeting.ageRange'
              Component={IntSlider}
              valueLabelDisplay="auto"
              min={18}
              max={65}
              sx={style.slider}
            />
            <Typography>{ageRange[1]}</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={style.lookalikes}>
        <Box sx={style.labelWithTooltip}>
          <Typography sx={style.label}>{t('lookalikes')}</Typography>
          <Tooltip title={t('lookalikesTooltip')}>
            <InfoIcon />
          </Tooltip>
        </Box>
        <Box sx={style.lookalikesIndicator}>
          <Typography sx={style.similarityLabel}>{t('none')}</Typography>
          <Typography sx={style.similarityLabel}>{t('similar')}</Typography>
        </Box>
        <IntFieldController
          control={control}
          name='targeting.similarity'
          Component={IntSlider}
          min={0}
          max={3}
          marks={[{ value: 1 }, { value: 2 }]}
          sx={style.slider}
        />
      </Box>
      <Typography sx={style.heading}>{t('autoEnhancementTitle')}</Typography>
      <Typography sx={style.description}>{t('autoEnhancementDescription')}</Typography>
      <Box sx={style.fieldCheckbox}>
        <IntFieldController
          control={control}
          name='isAutoEnhancement'
          Component={IntCheckbox}
        />
        <Box sx={style.labelWithTooltip}>
          <Typography sx={style.label}>{t('autoEnhancement')}</Typography>
          <Tooltip title={t('autoEnhancementTooltip')}>
            <InfoIcon />
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={style.divider}/>
      <Typography sx={style.heading}>{t('selectAudienceTitle')}</Typography>
      <Box sx={style.field}>
        <Typography sx={style.label}>{t('selectAudience')}</Typography>
        <CustomAudienceList name='targeting.segmentAudience.includeAny' placeholder={t('typeNameOfAudience')}/>
      </Box>
      {visibleInputs.excludeAudience && (
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('excludeAudience')}</Typography>
          <CustomAudienceList name='targeting.segmentAudience.exclude' placeholder={t('typeNameOfAudience')} />
        </Box>
      )}
      {visibleInputs.narrowAudience && (
        <Box sx={style.field}>
          <Typography sx={style.label}>{t('narrowAudience')}</Typography>
          <CustomAudienceList name='targeting.segmentAudience.include' placeholder={t('typeNameOfAudience')} />
        </Box>
      )}
      <Box sx={style.hashtags}>
        {!visibleInputs.excludeAudience && (
          <Box sx={style.hashtagLike} onClick={showExcludeAudienceInput}>
            <Typography sx={style.hashtagText}>{t('exclude')}</Typography>
          </Box>
        )}
        {!visibleInputs.narrowAudience && (
          <Box sx={style.hashtagLike} onClick={showNarrowAudienceInput}>
            <Typography sx={style.hashtagText}>{t('narrowAudience')}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

CampaignTargetingForm.displayName = 'CampaignTargetingForm';

export default CampaignTargetingForm;
