/**
 * Intent.ai Confidential
 * ----------------------
 * Created by Tigran Yengibaryan on 9/2/2023
 * All Rights Reserved.
 */
import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { AddIcon, getImageDetails, TrashIcon } from "../../../global";
import { style } from './style';
import { ChangeEvent, useEffect, useState } from "react";
import { NotificationService } from "../../../global/services";
import { useTranslation } from "react-i18next";
import { UploadService } from "../../../upload/services";
import { useFormContext, useWatch } from "react-hook-form";
import { BannerDTO } from "../../types";
import { creativeNameRunTimeValidator } from "../../utils";
import ApprobationStatus from "../ApprobationStatus";

type Props = {
  width: number;
  height: number;
  hasError: boolean;
}

function Banner({ width, height, hasError }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.visuals' });

  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState('');

  const { setValue, control,formState: {errors} } = useFormContext();

  const banners = useWatch({
    control,
    name: 'visuals.banners',
  });

  const currentImage = banners.find((banner: BannerDTO) => banner.width === width && banner.height === height);


  async function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    setIsLoading(true);
    const file = e.target.files?.[0];
    if (file) {
      const ext = file.name.substring(file.name.lastIndexOf("."));
      const dimensions = file.type === "application/x-zip-compressed" || ext === '.zip' ? null : await getImageDetails(file);
      if ((dimensions?.width && dimensions?.height && dimensions?.width >= width && dimensions?.height >= height && dimensions?.width / dimensions?.height === width / height ) || file.type === "application/x-zip-compressed" || ext === '.zip') {
        const {data:{ data }} = await UploadService.uploadFile2(file, width, height);
        if (data) {
          setValue(
            'visuals.banners',
            [...banners, { width, height, file_id: data.file_id, url: data.file_url, creative_name: `${width}x${height}`}]
          );
        }
      } else {
        NotificationService.handleError(t('bannerSize', { width, height }));
      }
    }
    setIsLoading(false);
  }

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const index = banners.findIndex((banner: BannerDTO) => banner.width === width && banner.height === height);
    if (index === -1) return;
    banners[index] = {...banners[index], creative_name: creativeNameRunTimeValidator(e.target.value, setNameError)}
    setValue(
      'visuals.banners',
      banners
    );
  }

  function onBannerRemove() {
    setValue(
      'visuals.banners',
      banners.filter((banner: BannerDTO) => banner.width !== width || banner.height !== height)
    );
  }

  useEffect(() => {
    const visuals = errors?.visuals as any;
    if(currentImage && !currentImage.creative_name &&
      hasError && !!visuals?.banners.filter((n: any) => n)[0] &&
      !!visuals?.banners.filter((n: any) => n)[0].creative_name)
      setNameError('Creative Name is a required field');
  }, [hasError, errors, currentImage]);
  

  return (
    <Box sx={style.bannerPreview}>
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <Typography sx={style.sizes}>{width}x{height}</Typography>
        <ApprobationStatus decision={!!currentImage && currentImage.creativeServingDecision} />
      </Box>
      <Typography sx={style.namesLabel}>CREATIVE NAME</Typography>
      <TextField
        disabled={banners.findIndex((banner: BannerDTO) => banner.width === width && banner.height === height) === -1}
        value={currentImage?.creative_name || ""}
        id="outlined-basic"
        variant="outlined"
        onChange={(e) => handleNameChange(e as ChangeEvent<HTMLInputElement>)}
        placeholder={'Enter banner name'}
        sx={{...style.names(nameError), }}
      />
      {!!nameError ? <Typography sx={style.errorText}>{nameError}</Typography> : null}
      <Box sx={[style.banner(width, height), !!currentImage ? style.imageWrapper : style.border(hasError)]}>
        {currentImage ? (
          <>
            {/* eslint-disable-next-line */}
            <iframe width={width} height={height} style={{
              border: '1px solid #2e79f7',
              boxSizing: 'unset',
              borderRadius: 1,
            }} scrolling='no' src={currentImage.url} />
            <Box sx={style.trashIcon} onClick={onBannerRemove}>
              <TrashIcon width={24} height={24} />
            </Box>
          </>
          ) : (
          <>
            {isLoading ? <CircularProgress sx={style.progress}/> : <AddIcon sx={style.addIcon} /> }
            <Typography sx={style.addBanner}>Add Image</Typography>
            <Box component='input' type='file' sx={style.hiddenInput} onChange={handleFileUpload}/>
          </>
        )}
      </Box>

    </Box>
  )
}

export default Banner;