import { GET, BASE_IMAGE_PATH } from "../_constants";
import { space_default, profile_default } from "../assets";

const getBaseURL = window.REACT_APP_BASE_URL;


const typeMap = {
  png: 1,
  jpg: 2,
  jpeg: 2,
  gif: 3,
  "svg+xml": 4,
  "vnd.microsoft.icon": 5,
  webp: 6,
};

const BASE_PREFIX_LENGTH = "data:image/".length;
const BASE_POSTFIX = ";base64,";

class _ImageUtils {
  convertUrlToBase64 = url =>
    new Promise((resolve, reject) => {
      try {
        const xhr = new XMLHttpRequest();
        xhr.onload = function onload() {
          const reader = new FileReader();
          reader.onloadend = function onloadend() {
            const i = new Image();
            let h ;
            let w ;
            i.onload = function() {
              w = i.width;
              h = i.height;
              resolve({
                file: reader.result,
                width: w,
                height: h,
              });
            };
            i.src = reader.result as string;
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.open(GET, url);
        xhr.responseType = "blob";
        xhr.send();
      } catch (e) {
        reject(e);
      }
    });

  getImageExtension = img => {
    const originalImageSemicolIndex = img.indexOf(BASE_POSTFIX);
    const originalImageExtension = img.substring(
      BASE_PREFIX_LENGTH,
      originalImageSemicolIndex
    );
    return typeMap[originalImageExtension];
  };

  parseProfileImage = profile => {
    if (profile === null || !profile.profile_image) {
      return profile;
    }
    const parsedProfile = { ...profile };
    if (parsedProfile.profile_image.original_picture_file) {
      parsedProfile.profile_image.original_picture_file = this.splitBase64ImagePrefix(
        parsedProfile.profile_image.original_picture_file
      );
    }
    if (parsedProfile.profile_image.picture_file) {
      parsedProfile.profile_image.picture_file = this.splitBase64ImagePrefix(
        parsedProfile.profile_image.picture_file
      );
    }
    return parsedProfile;
  };

  splitBase64ImagePrefix = base64 => (base64 ? base64.split(",")[1] : base64);

  getImageObject = async (img, needSplit = true) => {
    const { name: file_name } = img;
    const preview = URL.createObjectURL(img);
    const fileConverted: any = await this.convertUrlToBase64(preview);
    const file = needSplit
      ? this.splitBase64ImagePrefix(fileConverted.file)
      : fileConverted.file;
    const file_type = this.getImageExtension(fileConverted.file);
    return {
      file_id: "",
      file,
      preview,
      file_type,
      file_name,
      deleted: false,
      width: fileConverted.width,
      height: fileConverted.height,
      size: img.size ? img.size / 1000 : null,
    };
  };

  getSpaceImage = spaceImageId => {
    return spaceImageId
      ? `${getBaseURL()}/v1${BASE_IMAGE_PATH}${spaceImageId}`
      : profile_default;
  };
}

export const ImageUtils = new _ImageUtils();

