import { ApiService } from "../../global/services";
import { CampaignFormDTO } from "../../campaign/types";
import { AudienceForm } from "../types/audience";

class AudienceService {
  static ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/audience`;

  static SEGMENT_AUDIENCE_ENDPOINT = `${window.REACT_APP_BASE_URL}/v1/segment-audience`


  static getAudienceResource(searchTerm: string) {
    return ApiService.get(`${AudienceService.ENDPOINT}/audience-resource?name=${searchTerm}`);
  }
  static getRewordAdSpot(id: string, multiplier: number, budget: string | number ) {
    return ApiService.get(`${window.REACT_APP_BASE_URL}/v1/rewarded/ad/spot/calculator?campaign_id=${id}&cpm_multiplier=${multiplier}&daily_budget=${budget}` );
  }

  static getAudienceSize({ targeting, cpm }: { targeting: CampaignFormDTO['targeting']; cpm: number; }) {
    const body = {
      apps: {
        include: (targeting.apps?.include || []).map(({ id }: { id: string }) => id),
        exclude: (targeting.apps?.exclude || []).map(({ id }: { id: string }) => id),
        includeAny: (targeting.apps?.includeAny || []).map(({ id }: { id: string }) => id),
      },
      cpm,
      gender: targeting.gender,
      fromAge: targeting.ageRange?.[0] || 18,
      toAge: targeting.ageRange?.[1] || 65,
      interests: {
        include: (targeting.interests?.include || []).map(({ value }) => value),
        exclude: (targeting.interests?.exclude || []).map(({ value }) => value),
        includeAny: (targeting.interests?.includeAny || []).map((item) => item.value || item),
      },
      languages: {
        include: [],
        exclude: [],
        includeAny: [],
      },
      segment_audiences: {
        include: (targeting.segmentAudience?.include || []).map(({ id }: { id: string }) => id),
        exclude: (targeting.segmentAudience?.exclude || []).map(({ id }: { id: string }) => id),
        includeAny: (targeting.segmentAudience?.includeAny || []).map(({ id }: { id: string }) => id),
      },
      similarity: targeting.similarity,
      banners: [],
      behaviors: {
        includeAny: [],
        include: [],
        exclude: [],
      },
      locations: {
        includeAny: [],
        include: [],
        exclude: [],
      },
      websites: {
        includeAny: [],
        include: [],
        exclude: [],
      },
      campaign_id: null,
      location_option_key: '1',
    };
    return ApiService.post(`${AudienceService.ENDPOINT}/audience-size`, body)
  }

  static getAudienceGauge(body: any) {
    return ApiService.post(`${AudienceService.ENDPOINT}/placements-gauge`, body);
  }

  static uploadAudience(data: AudienceForm, userId: string) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('file', data.file as File);
    formData.append('user', userId);
    return ApiService.post(`${AudienceService.SEGMENT_AUDIENCE_ENDPOINT}/upload`, formData,{'Content-Type': 'text/csv'});
  }

  static getAudienceList(userId: string, filters: any = {}) {
    return ApiService.post(`${AudienceService.SEGMENT_AUDIENCE_ENDPOINT}/list`, {
      space_id: userId,
      ...filters,
    });
  }

  static getAudienceById(audienceId: string) {
    return ApiService.get(`${AudienceService.SEGMENT_AUDIENCE_ENDPOINT}/${audienceId}`);
  }

  static deleteAudience(audienceId: string) {
    return ApiService.delete(`${AudienceService.SEGMENT_AUDIENCE_ENDPOINT}/delete/${audienceId}`);
  }
}

export default AudienceService;
