
import { isArray, keys } from "lodash";
// import { ObjectUtils } from "./ObjectUtils";

// const { getFieldInObject, mergeDeep, checkObjectIsExist } = ObjectUtils;

class _ValidateUtils {
  emailExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

  armenianLanguageExp = /([\u0530-\u058F\s]*)$/g;

  inventoryURLExp = /^(?:\/\/|[^\/]+)*/;

  // At least 8 characers, 1 uppercase, 1 lowercase, 1 number
  passwordExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/i;
  // passwordExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#^])[A-Za-z\d$@$!%*?&#^]{8,}/i;

  // urlExp = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/i;

  urlExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

  // including alphanumeric and special characters
  alphaNumericSymbolExp = /^[a-zA-Z0-9#@$-/:-?{-~!"^_`\[\] ]*$/i; // eslint-disable-line no-useless-escape

  domainExp = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/i;

  // including only numbers
  numbersExp = /^\d+$/;

  // phone numbers
  phoneNumberExp = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/; // eslint-disable-line no-useless-escape

  isValidDomain = (domain: string) => this.domainExp.test(domain);

  /**
   * Methods for usage in validation configuration
   */
  methods = {
    isArmenian: { type: "isArmenian" },
    isInventoryURLExp: { type: "isInventoryURLExp" },
    isEmail: { type: "isEmail" },
    isMin: { type: "isMin" },
    isMinNumber: { type: "isMinNumber" },
    isMax: { type: "isMax" },
    isMax300: { type: "isMax300" },
    isEmpty: { type: "isEmpty" },
    isEmptyIfCondition: { type: "isEmptyIfCondition" },
    isPassword: { type: "isPassword" },
    isUrl: { type: "isUrl" },
    isHttpsURL: { type: "isHttpsURL"},
    isAlphaNumericSymbol: { type: "isAlphaNumericSymbol" },
    isNumbers: { type: "isNumbers" },
    isPhoneNumber: { type: "isPhoneNumber" },
    isImage: { type: "isImage" },
    isOfSize: {
      width: value => ({ type: "isOfSizeWidth", value }),
      height: value => ({ type: "isOfSizeHeight", value }),
    },
    matches: {
      to: value => ({ type: "matchesTo", value }),
      notTo: value => ({ type: "matchesNotTo", value }),
    },
    customMethod: { type: "customMethod" },
    isValidDomain: { type: "isValidDomain" },
  };

  /**
   * Checkers
   */
  isArmenian = (value: string = ""): boolean => {
    if (!value) return undefined;
    const matchResults = value.trim().match(this.armenianLanguageExp);
    return matchResults ? !!matchResults[0] : undefined;
  };

  isEmpty = (value: string): boolean => {
    if (value && typeof value === "string") {
      return value.trim().length === 0;
    }
    return !value;
  };

  isMin = (number: number) => (value: String) =>
    value ? value.length >= number : undefined;

  isMinNumber = (number: number) => (value: String) =>
    value ? Number(value) >= Number(number) : undefined;

  isMax = (number: number) => (value: String) =>
    value ? value.length <= number : undefined;

  isEmail = (value: string): boolean => this.emailExp.test(value);

  isUrl = (value: string | Array<string>): boolean => {
    if (!value) return true;
    if (typeof value === "string") {
      return !!value.match(this.urlExp);
    }
    return value.every(item => !!item.match(this.urlExp));
  };

  isHttpsURL = (value: string): boolean => {
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      .test(value);
  };

  isInventoryURLExp = (value: string | Array<string>): boolean => {
    if (!value) return true;
    if (typeof value === "string") {
      const val = value.match(this.inventoryURLExp);
      if (value === val[0]) {
        return false;
      }
    }
    return true;
  };

  isAlphaNumericSymbol = (value: string): boolean =>
    this.alphaNumericSymbolExp.test(value) || !value;

  isNumbers = (value: string): boolean => this.numbersExp.test(value) || !value;

  isPhoneNumber = (value: string) => this.phoneNumberExp.test(value) || !value;

  isPassword = (value: string) => this.passwordExp.test(value);

  isGreaterThan = (value1: string, value2: string): boolean => value1 > value2;

  matchesTo = (value1: string, value2: string): boolean =>
    !value1 || value1 === "" || !value2 || value2 === ""
      ? true
      : value1 === value2;

  matchesNotTo = (value: string, values: Array<String>): boolean => {
    const isMatch = values.some(item => item && item.includes(value));
    return !isMatch;
  };

  // TEMP: Image validations are expected to receive blobs as values
  isOfSizeWidth = (value: any, width: number): boolean =>
    value && value.width === width;

  isOfSizeHeight = (value: any, height: number): boolean =>
    value && value.height === height;

  /**
   * Validation configuration parsing and logic handlers
   */
  handleSingleCheck = (values, config, key) => {
    const {
      isArmenian,
      isInventoryURLExp,
      isEmpty,
      isEmail,
      matchesTo,
      matchesNotTo,
      isOfSizeHeight,
      isOfSizeWidth,
      isPassword,
      isPhoneNumber,
      isUrl,
      isAlphaNumericSymbol,
      isNumbers,
      isMin,
      isMax,
      isMinNumber,
      isHttpsURL,
    } = this;
    const { method, message } = config;
    const value = values[key] ? values[key] : null;
    const result: any = {};
    switch (method.type) {
      case "isArmenian":
        result.isValid = isArmenian(value);
        result.message = message;
        break;
      case "isInventoryURLExp":
        result.isValid = !isInventoryURLExp(value);
        result.message = message;
        break;
      case "isEmpty":
        result.isValid = !isEmpty(value);
        result.message = message;
        break;
      case "isEmptyIfCondition":
        const { conditionFunction } = config;
        result.isValid = conditionFunction(values) ? !isEmpty(value) : true;
        result.message = message;
        break;
      case "isEmail":
        result.isValid = isEmail(value);
        result.message = message;
        break;
      case "isUrl":
        result.isValid = isUrl(value);
        result.message = message;
        break;
      case "isHttpsURL":
        result.isValid = isHttpsURL(value);
        result.message = message;
        break;
      case "isMin":
        result.isValid = value && isMin(2)(value);
        result.message = message;
        break;
      case "isMinNumber":
        const { range } = config;
        result.isValid = value && isMinNumber(range)(value);
        result.message = message;
        break;
      case "isMax300":
        result.isValid = value && isMax(300)(value);
        result.message = message;
        break;
      case "isMax":
        const { maxValue } = config;
        result.isValid = value && isMax(maxValue)(value);
        result.message = message;
        break;
      case "isAlphaNumericSymbol":
        result.isValid = isAlphaNumericSymbol(value) || !value;
        result.message = message;
        break;
      case "isNumbers":
        result.isValid = isNumbers(value) || !value;
        result.message = message;
        break;
      case "isPhoneNumber":
        result.isValid = isPhoneNumber(value) || !value;
        result.message = message;
        break;
      case "matchesTo":
        const comparison = values[method.value];
        result.isValid = matchesTo(value, comparison);
        result.message = message;
        break;
      case "customMethod":
        result.isValid = config.customMethod(values);
        result.message = message;
        break;
      case "matchesNotTo":
        const fields = method.value.split(" ");
        const comparisons = fields.map(field => values[field]);
        result.isValid = matchesNotTo(value, comparisons);
        result.message = message;
        break;
      case "isOfSizeWidth":
        const width = method.value;
        result.isValid = isOfSizeWidth(value, width);
        result.message = message;
        break;
      case "isOfSizeHeight":
        const height = method.value;
        result.isValid = isOfSizeHeight(value, height);
        result.message = message;
        break;
      case "isPassword":
        result.isValid = isPassword(value);
        result.message = message;
        break;
      default:
        break;
    }
    return result;
  };

  handleArrayCheck = (values, configs, key) => {
    const { handleSingleCheck } = this;
    const result: any = {};
    let modified = false;
    configs.forEach(config => {
      const { isValid, message } = handleSingleCheck(values, config, key);

      if (!isValid && !modified) {
        result.isValid = isValid;
        result.message = message;
        modified = true;
      }
    });

    return result;
  };

  handleFeildArrayCheck = (values: any, configs: Object, key: string) => {
    const errorsArray = [];
    values[key].forEach((value, index) => {
      errorsArray[index] = this.validate(configs)(value);
    });
    return { message: errorsArray, isValid: false };
  };

  checkField = (values: any, validationConfig: Object) => (key: String) => {
    const { handleSingleCheck, handleArrayCheck, handleFeildArrayCheck } = this;
    const config = validationConfig[key as keyof typeof validationConfig];
    // if (isArray(values[key]) && typeof values[key][0] === "object") {
    //   return handleFeildArrayCheck(values, config, key);
    // }
    if (isArray(config)) {
      return handleArrayCheck(values, config, key);
    }
    return handleSingleCheck(values, config, key);
  };

  formatValidationErrorsNestedFields = obj =>
    Object.entries(obj).reduce((acc, [fieldName, value]) => {
      // if (fieldName.includes(".")) {
      //   const objKeys = fieldName.split(".");
      //
      //   const object = {};
      //   objKeys.reduce((o, s, index) => {
      //     if (index === objKeys.length - 1) {
      //       return (o[s] = value);
      //     }
      //     return (o[s] = {});
      //   }, object);
      //
      //   return mergeDeep(acc, object);
      // }
      return { ...acc, [fieldName]: value };
    }, {});

  validate = (validationConfig: Object) => (values: Object) => {
    const fieldChecker = this.checkField(values, validationConfig);
    const configKeys = keys(validationConfig);
    const errors = {};
    configKeys.forEach(key => {
      const { isValid, message } = fieldChecker(key);
      if (!isValid) {
        errors[key] = message;
      }
    });
    return this.formatValidationErrorsNestedFields(errors);
  };

  // scrollToFirstError = errors => {
  //   // TODO: Rewrite getFirstError Function
  //   const getFirstError = (obj, x) => {
  //     for (const key in obj) {
  //       if (!obj[key]) continue;
  //       if (obj.hasOwnProperty(key)) {
  //         if (obj[key] && obj[key].id) {
  //           return x ? `${x}.${key}` : key;
  //         }
  //       }
  //       if (typeof obj[key] === "object") {
  //         return getFirstError(obj[key], x ? `${x}.${key}` : key);
  //       }
  //     }
  //   };
  //   const firstError = getFirstError(errors);
  //
  //   const el = document.querySelector(`[name="${firstError}"]`);
  //   if (el) {
  //     const position =
  //       el.getBoundingClientRect().top + document.documentElement.scrollTop;
  //
  //     const offset = 200;
  //
  //     window.scrollTo({ top: position - offset, behavior: "smooth" });
  //   }
  // };
}

export const ValidateUtils = new _ValidateUtils();
