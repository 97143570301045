import React from "react";
import AdminHeader from "../../components/AdminHeader";
import { useTranslation } from "react-i18next";
import InvoicesList from "../../../../screens/Billing/components/invoicesList";


const Invoice = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin.invoice' });

  return (
    <div>
      <AdminHeader title={t("title")} >
        <div style={{height: '600px'}}>
          <InvoicesList />
        </div>
      </AdminHeader>
    </div>
  );
}

export default Invoice;
