
import "./style.scss";
import { TextField } from "@mui/material";

interface IProps {
  label?: string;
  type?: string;
  value?: any;
  variant?: 'outlined' | 'filled' | 'standard';
  disabled?: boolean;
  autoFocus?: boolean;
  currentValue?: any;
  fullWidth?: boolean;
  placeholder?: string;
  input?: any;
  className?: string;
  inputClassName?: string;
}

const InputRow = ({...props}: IProps) => {
  const { className = '', currentValue, fullWidth = false, placeholder, autoFocus = false, input = {}, label, disabled, variant = 'outlined', type} = props;
  const {name, value, onChange, select, options} = input;

  console.log(666222,value);
  console.log(666333,currentValue);

  const _value = type === 'file' ? value?.file_name : value;

  return (
    <div className={`input-row ${fullWidth ? 'w-100' : ''} ${className}`}>
      {label ? (<div className={'label-wrapper'}>
        <label htmlFor={`${name}`} className={'label'}>{label}</label>
      </div>) : null}
      <div className={`input-wrapper ${fullWidth ? 'w-100' : ''}`}>
        <TextField
          name={name ? name : ''}
          value={currentValue ? currentValue : _value}
          className={`input-field ${disabled ? 'disabled' : ''}`}
          id={`${name}`}
          variant={variant}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={disabled}
          type={`${type}`}
          select={select}
          onChange={onChange}
          children={options}
        />
      </div>
    </div>
  )
}

export default InputRow;