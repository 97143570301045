import { FieldErrors } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { FormEvent, useMemo } from "react";
import { BASE_IMAGE_PATH } from "../../../_constants";
import profileDefaultImage from "../../../assets/images/profile_default.jpg";
import { isDev, isLocal, isNG } from "../../../_constants/contentType";

//================= number utils ==============
function numberToStringWithCommas(num: number, decLength: number = 0): any {
  // This function will return convert large number to numbers with commas
  // eg: 123124 => 123,124
  return num === null ? 0 :
   num.toFixed(decLength).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

function getPercentageOfValue (from: number, value: number) {
  var percentage = Math.round(value / from * 100);
  if (percentage === 100) {
    return 1;
  }
  return Number(`0.${percentage < 10 ? "0" + String(percentage) : percentage}`);
};

function uuidv4 () {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0,
      // eslint-disable-next-line
      v = c === "x" ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}

//======================== number utils end =============

function getImageDetails(file: File) {
  return new Promise<{ width: number; height: number; name: string; size: number }>((resolve) => {
    const image = new Image();
    image.onload = function handleLoad() {
      resolve({
        height: +image.height,
        width: +image.width,
        size: file.size,
        name: file.name,
      });
    };
    image.src = URL.createObjectURL(file);
  });
}

function withStopPropagation(callback: Function) {
  return (e: FormEvent) => {
    e.stopPropagation();
    return callback(e);
  };
}

function getFieldErrorMessage(errors: FieldErrors, name: string) {
    if (name.includes('.')) {
      const nestedNames = name.split('.');
      return nestedNames.reduce((accum: any, curr: string) => {
        return accum?.[curr];
      }, errors)?.message;
    }
    return errors[name]?.message;
}

function getFirstErrorMessage(errors: FieldErrors) {
  const firstError = Object.values(errors)[0];
  if (firstError) {
    if (firstError.message) {
      return firstError.message;
    }
    return Object.values(firstError)[0]?.message;
  }
  return '';
}

function getFirstErrorKey(errors: FieldErrors): any {
  const firstKey = Object.keys(errors)[0];
  if (errors[firstKey]) {
    if (errors[firstKey]?.message || Array.isArray(errors[firstKey])) {
      return firstKey;
    }
    return getFirstErrorKey(errors[firstKey] as object);
  }
  return '';
}

function abbreviateNumber(num: number) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

function getFileFromCache(fileID: string) {
  return `${window.REACT_APP_BASE_URL}/v1/file/cache/${fileID}`;
}

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function areObjectsEqual(obj1: Record<string, any>, obj2: Record<string, any>) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    } else if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
      if (!areArraysEqual(obj1[key], obj2[key])) {
        return false;
      }
    } else if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}

function areArraysEqual(arr1: Array<any>, arr2: Array<any>) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
      if (!areArraysEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (typeof arr1[i] === 'object' && typeof arr2[i] === 'object') {
      if (!areObjectsEqual(arr1[i], arr2[i])) {
        return false;
      }
    } else if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

// The function returns the length of a string in UTF8 format
// In the backend we use Go, which uses UTF8 encoding
// So our validation for native ads should be based on UTF8 length
function getUTF8Length(str: string) {
  const blob = new Blob([str]);
  return blob.size;
}

function downloadBlob(_blob: Blob, fileName?: string) {
  const blob = new Blob([_blob], {type: 'text/csv;charset=utf-8'});

  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName || 'download.csv';

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export function getSpaceImage(imageId?: string) {
  return imageId ? `${window.REACT_APP_BASE_URL}/v1${BASE_IMAGE_PATH}${imageId}` : profileDefaultImage;
}

export function getDefaultCPX (): number {
  const currency = localStorage.getItem('currentAccountCurrency');
  if(isNG || isDev || isLocal){
    return currency === 'USD' ? 0.1 : currency === 'NGN' ? 130 : 0.35; // todo the last one for another cases
  } else {
  return 0.35
  }
}

export const goToRouteLinkWithId = (route: string, id: string) =>
  route.replace(":id", id);


export {
  getImageDetails,
  withStopPropagation,
  getFieldErrorMessage,
  getFileFromCache,
  abbreviateNumber,
  useQuery,
  getFirstErrorMessage,
  getFirstErrorKey,
  areObjectsEqual,
  getUTF8Length,
  downloadBlob,

  uuidv4,
  getPercentageOfValue,
  numberToStringWithCommas,
}