import React, { useRef, useEffect } from "react";
// import { InputErrorMessage } from "../InputErrorMessage/InputErrorMessage";
import "./style.scss";
import { InputErrorMessage } from "../inputErrorMessage";

interface IProps {
  label: string;
  type: string;
  prepend: string;
  append: string;
  disabled: boolean;
  maxLength: string | number;
  placeholder: string;
  input: any;
  inputStyle: any;
  prependStyle: any;
  appendStyle: any;
  inputType: string;
  meta?: any;
  onChange?: any;
  currentValue: any;
  max?: string | number;
  min?: string | number;
  info?: string;
  showCharCount?: any;
  icon?: any;
  autoFocus?: boolean;
}

function ValidatableInput(props: IProps) {
  const {
    prepend,
      type = 'text',
      append,
      disabled = false,
      input,
      maxLength = 5000,
      label,
      placeholder = label,
      inputStyle,
      prependStyle,
      appendStyle,
      meta,
      inputType = "input",
      onChange,
      currentValue,
      max,
      min,
      info,
      showCharCount,
      icon,
      autoFocus,
  ...rest
  } = props;
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);
  const _onChange = e => {
    if (type === "number") {
      const re = /^[0-9\b]+$/;
      // if (e.target.value !== "" && !re.test(e.target.value)) {
      //   return false;
      // }
      if (max && e.target.value.length - 1 === max) {
        return false;
      }
    }
    if (typeof onChange === "function") {
      onChange(e);
    }
    if (input && typeof input.onChange === "function") {
      input.onChange(e);
    }
    return false;
  };
  const Tag = `${inputType}`;
  return (
    <>
      <div className="mai-col mai-col-12  mai-col-mobile">
        <label className="label" htmlFor={input && input.name}>{label}</label>
      </div>
      <div className="mai-form-group text-left">
        <div className="mai-input-group">
          {prepend && (
            <div className="mai-input-group-prepend">
            <span
              style={prependStyle}
              className="mai-input-group-text"
              id="basic-addon3"
            >
              {prepend}
            </span>
            </div>
          )}
          <Tag
            {...rest}
            {...input}
            disabled={disabled}
            type={type}
            className="mai-form-control w-100"
            id={input && input.name}
            maxLength={maxLength}
            placeholder={placeholder}
            value={input ? input.value : currentValue}
            name={input && input.name}
            onChange={_onChange}
            style={inputStyle}
            max={max}
            min={min}
            ref={inputRef}
          />

          {info && <small className="info">{info}</small>}
          {append && (
            <div className="mai-input-group-append">
            <span
              className="mai-input-group-text"
              id="basic-addon2"
              style={appendStyle}
            >
              {append}
            </span>
            </div>
          )}
        </div>
        {meta && meta.touched && meta.error && meta.error.id && (
          <InputErrorMessage {...meta.error} />
        )}
        {showCharCount ? (
          <div className="input-charcount">
            {input.value.length}/{maxLength}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ValidatableInput;
