import {
  Box,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { style } from "./style";
import { invoicesHeadConfig } from "./config";
import React from "react";

interface IProps {
  row: any;
  currency: string;
}

function InvoicesRow(props: IProps) {
  const {row, currency} = props;

  return (
    <TableRow
      sx={[style.tableRow]}
    >
      {invoicesHeadConfig.map(cell => {
          return (
            <TableCell key={cell.key}>
              <Box sx={style.rowCollection}>
                <Typography title={cell.parser(row[cell.key], currency)} sx={style.rowText}>
                  {cell.parser(row[cell.key], currency)}
                </Typography>
              </Box>
            </TableCell>
          )
        })
      }
      <TableCell>
        <Box sx={style.rowCollection}>
          <Typography onClick={ () => console.log(666, 'download', row)} sx={style.rowText}>
            <DownloadIcon />
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default InvoicesRow;