import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog, DialogContent, DialogTitle, IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { style } from './style';

import React, { useCallback, useEffect, useState } from "react";

import { CampaignService } from "../../services";
import { StatusCode } from "../../../global/types";
import TableBodySkeleton from "../../../global/components/TableBodySkeleton";
import { useParams } from "react-router-dom";
import ChangeLogListRow from "../ChangeLogListRow";
import ReactJsonViewCompare from 'react-json-view-compare';
import NoCampaignLog from "../NoCampaignLog";
import moment from "moment";


function ChangeLogList() {
  const { t } = useTranslation('translation', { keyPrefix: 'campaign.log' });

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const { id } = useParams<{ id?: string; }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [logData, setLogData] = useState<any[]>([]);
  const [selectedLog, setSelectedLog] = useState<any>(null);

  const createShowString = (properties: any[]) : string => {
    let showStr = "";
    properties.forEach((p) => {
      showStr += `${p.path}: ${p.value}, `
    })
    return `{ ${showStr} }`;
  }

  const getLog = useCallback((id: string) => {
    setIsLoading(true);
    const parsedData: any[] = [];
    CampaignService.getChangeLog(id as string)
      .then(({ data }) => {
        if (data.type?.code === StatusCode.OK) {

          data.data.forEach((item: any) => {
              const obj = {
                authorName: item.author.email || item.author.name,
                difference: createShowString(item.state_difference),
                date: item.commit_date,
                previousState: item.previous_state,
                currentState: item.current_state,
              };
              parsedData.push(obj);
          })

          setIsLoading(false)
          setLogData(parsedData);
        }
      });
  }, []);


 useEffect(() => {
   if(id){
     getLog(id)
   }
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [id]);


  function handleInfoModalOpen(log: any) {
    console.log(666,log);
    setSelectedLog(log);
    setInfoModalOpen(true);
  }

  function handleInfoModalClose() {
    setSelectedLog(null);
    setInfoModalOpen(false);
  }

  return (
    <>
      <Paper>
        <TableContainer sx={style.tableContainer}>
          {!!logData.length && (
            <Table sx={{}}>
              <TableHead>
                <TableRow>
                  <TableCell size="small" sx={style.headCell}>
                    {t("date")}
                  </TableCell>
                  <TableCell size="small" sx={style.headCell}>
                    {t("state")}
                  </TableCell>
                  <TableCell size="small" sx={style.headCell}>
                    {t("user")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {id ? (
                  isLoading ? (
                    <TableBodySkeleton rowCount={10} cellCount={4} />
                  ) : logData.map((row: any, index: number) => (
                    <ChangeLogListRow
                      data={row}
                      key={index}
                      onRowClick={() => {
                        handleInfoModalOpen(row);
                      }}
                    />
                  ))
                ) : null}
              </TableBody>
            </Table>)}
          {!id || !logData.length ? (
            <NoCampaignLog />
          ) : null}
        </TableContainer>
      </Paper>

      {!!selectedLog && (
        <Dialog
          sx={style.dialog}
          // className='modal-dialog'
          open={infoModalOpen}
          onClose={handleInfoModalClose}
        >
          <DialogTitle sx={{padding: '16px 24px'}}>
            <Typography>
              {`Log Info (${moment(selectedLog.date).format('MMM DD, YYYY HH:mm')})`}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleInfoModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Box sx={{display: 'flex'}}>
            <ReactJsonViewCompare  newData={selectedLog.currentState} oldData={selectedLog.previousState} />
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

ChangeLogList.displayName = 'ChangeLogList';

export default ChangeLogList;